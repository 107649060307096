(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var consent;
window.addEventListener("load", function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#252e39"
            },
            "button": {
                "background": "#14a7d0"
            }
        },
        "theme": "edgeless",
        "position": "top",
        "static": true,
        "content": {
            "href": "http://en-rs.wyser-search.com/terms-of-use",
            "link": cookies.link,
            "message": cookies.message,
            "dismiss": cookies.dismiss,
            "allow": cookies.agree,
            "deny": cookies.deny
        }
    }, function (popup) {
        consent = popup;
    }, function (err) {
        console.error(err);
    });
});

function initAjaxLoad() {
    $('body').addClass('ajaxloading');
}

function completeAjaxLoad() {
    $('body').removeClass('ajaxloading');
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    beforeSend: function beforeSend() {
        initAjaxLoad();
    },
    success: function success() {
        completeAjaxLoad();
    },
    complete: function complete() {
        completeAjaxLoad();
    },
    error: function error() {
        completeAjaxLoad();
    }
});

$('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
    placement: 'top',
    html: true,
    delay: { 'show': 500, 'hide': 100 }
});
$('body').popover({
    container: 'body',
    selector: '[data-toggle="popover"]',
    html: true,
    trigger: 'hover focus',
    delay: { 'show': 500, 'hide': 100 }
});

$('body .dropdown-toggle').dropdown();

function setModalMaxHeight(element) {

    top.$element = $(element);
    top.$content = top.$element.find('.modal-content');
    var borderWidth = top.$content.outerHeight() - top.$content.innerHeight();
    var dialogMargin = $(window).width() < 768 ? 20 : 60;
    var contentHeight = $(window).height() - (dialogMargin + borderWidth);
    var headerHeight = top.$element.find('.modal-header').outerHeight() || 0;
    var footerHeight = top.$element.find('.modal-footer').outerHeight() || 0;
    var maxHeight = contentHeight - (headerHeight + footerHeight);

    top.$content.css({
        'overflow': 'hidden'
    });
    top.$element.css({ 'top': '80px' });
    top.$element.find('.modal-body').css({
        'max-height': maxHeight,
        'overflow-y': 'auto'
    });
}

$('.modal').on('show.bs.modal', function () {
    $(this).show();
    if ($('#avatarModal').find('.modal-dialog').width() < $('.preview-container').width() + 35) {
        $('#avatarModal').find('.modal-dialog').addClass('modal-lg');
        $('.modal-dialog').trigger('contentchanged');
    } else {
        $('#avatarModal').find('.modal-dialog').removeClass('modal-lg');
        $('.modal-dialog').trigger('contentchanged');
    }
    setModalMaxHeight(this);
});

$(window).on('resize', function () {
    if ($('.modal.in').length != 0) {
        setModalMaxHeight($('.modal.in'));
    }
});

$('.modal-dialog').on('bind', 'contentchanged', function () {
    // do something after the div content has changed
    if ($('.modal.in').length != 0) {
        setModalMaxHeight($('.modal.in'));
    }
});
$('select').select2({
    theme: 'bootstrap',
    placeholder: i18swal.select,
    language: locale,
    allowClear: true
});
$('.select2-container').tooltip({
    title: function title() {
        return $(this).prev().attr('data-original-title');
    },
    container: 'body',
    placement: 'top',
    html: true,
    delay: { 'show': 500, 'hide': 100 }
});

// Scroll Top Link
$('#footer-scroll-top-link').on('click', function (e) {
    e.preventDefault();
    $('html, body').stop().animate({
        scrollTop: 0
    }, 400);
});

$(':submit').on('click', function (event) {
    if (event.currentTarget.className === 'open-responsive-menu') {
        return false;
    }
    var form = $(this).parents('form:first');
    event.preventDefault();
    var self = this;
    setTimeout(function () {
        $(self).prop('disabled', true);
    }, 0);
    setTimeout(function () {
        $(form).submit();
    }, 1);
});

$('#selectnav1').select2('destroy');

},{}]},{},[1]);
